import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const axios = require("axios").default;

export default class Form extends React.Component {
  state = {
    isSubmitting: false,
    firstName: "",
    contactNumber: "",
    email: "",
    typeOfHouse: "BTO",
    remarks: "",
    expectedDate: new Date(),
    msg: "",
    error: false
  };

  handleSubmition = (e) => {
    e.preventDefault();

    if (this.state.firstName == "") {
      this.setState({ msg: "First Name is required", error: true });
      return;
    } else if (this.state.contactNumber == "") {
      this.setState({ msg: "Contact Number is required", error: true });
      return;
    } else if (this.state.email == "") { 
      this.setState({ msg: "Email is required", error: true });
      return;
    }

    this.setState({ isSubmitting: true, msg: "", error:false });

    axios
      .post("https://email.api.innovten.com/v1/email", {
        apiKey: "2110116T70vuBS1U",
        data: {
          name: this.state.firstName,
          phone: this.state.contactNumber,
          email: this.state.email,
          expectedDate: moment(this.state.expectedDate).format("D MMM YYYY"),
          typeOfHouse: this.state.typeOfHouse,
          remarks: this.state.remarks,
        },
      })
      .then((response) => {
        this.setState({
          firstName: "",
          contactNumber: "",
          email: "",
          typeOfHouse: "BTO",
          remarks: "",
          expectedDate: new Date(),
          isSubmitting: false,
          msg: "We have received your message and will reply you as soon as possible",
        });
      })
      .catch((error) => {
        this.setState({ isSubmitting: false, msg: error, error: true });
      });
  };

  render() {
    return (
      <form action="#" method="post" className="elegant text-left mb-5">
        {/* <div className="form-group hid">
          <label htmlFor="ipCountry">
            Country Name<span className="text-danger">*</span>
          </label>
          <input id="ipCountry" type="text" name="det" autoComplete="country-name" />
        </div> */}
        <div className="form-group">
          <label htmlFor="ipName">
            First Name <span className="text-danger">*</span>
          </label>
          <input
            id="ipName"
            type="text"
            autoComplete="name"
            value={this.state.firstName}
            onChange={(e) => this.setState({ firstName: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="ipContact">
            Contact Number <span className="text-danger">*</span>
          </label>
          <input
            id="ipContact"
            type="tel"
            autoComplete="tel"
            value={this.state.contactNumber}
            onChange={(e) => this.setState({ contactNumber: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="ipEmail">
            Email Address <span className="text-danger">*</span>
          </label>
          <input
            id="ipEmail"
            type="email"
            autoComplete="email"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="ipStartDate">
            Expected Renovation Start Date <span className="text-danger">*</span>
          </label>
          <DatePicker
            dateFormat="yyyy/MM/dd"
            minDate={new Date()}
            selected={this.state.expectedDate}
            onChange={(date) =>
              this.setState({
                expectedDate: date,
              })
            }
          />
        </div>
        <div className="form-group">
          <label htmlFor="ipTOH">
            Type of House <span className="text-danger">*</span>
          </label>
          <select
            className="form-control"
            id="ipTOH"
            value={this.state.typeOfHouse}
            onChange={(e) => this.setState({ typeOfHouse: e.target.value })}
            required
          >
            <option value="BTO">BTO</option>
            <option value="RESALE_HDB">Resale HDB</option>
            <option value="EC">EC</option>
            <option value="CONDO">Condo</option>
            <option value="RESALE_CONDO">Resale Condo</option>
            <option value="LANDED">Landed</option>
            <option value="OTHERS">Others</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="ipRemarks">Remarks (Optional)</label>
          <textarea
            id="ipRemarks"
            rows="4"
            cols="50"
            value={this.state.remarks}
            onChange={(e) => this.setState({ remarks: e.target.value })}
          ></textarea>
        </div>
        <div className="mt-5 text-center">
          <p className="small">
            I understand that the details provided in this form will be shared with <strong>Edgeline Planners</strong>
          </p>
          <button
            onClick={this.handleSubmition}
            disabled={this.state.isSubmitting}
            className="btn btn-dark-outline mt-2"
            type="submit"
          >
            {!this.state.isSubmitting && "ACKNOWLEDGED & SUBMIT"}
            {this.state.isSubmitting && "SUBMITTING"}
          </button>
          <p className={`${this.state.error ? 'text-danger' : 'text-success' } mt-3`}>{this.state.msg}</p>
        </div>
      </form>
    );
  }
}
