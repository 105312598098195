import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import ReactCompareImage from "react-compare-image";

import SEO from "../components/seo";
import Header from "../components/header";
import HowItWorks from "../components/howitworks";
import Testimonial from "../components/testimonial";
import SectionTextButton from "../components/section-text-button";
import FAQSection from "../components/faqs";
import Scroll from "../components/scroll";
import VideoPlayer from "../components/video-player";
import SignUpForm from "../components/form";
import SocialLinks from "../components/social";
import AppointmentCode from "../components/appointment";

import { ReactComponent as BillIcon } from "../../static/svgs/icon-bill.svg";
import { ReactComponent as CommentIcon } from "../../static/svgs/icon-comment.svg";
import { ReactComponent as TeamIcon } from "../../static/svgs/icon-team.svg";

export default function Site({ data }) {
  return (
    <>
      <SEO title="Interior Design Singapore | Free Interior Design Visualisation" />
      <div
        style={{ paddingTop: "8px", paddingBottom: "8px" }}
        className="bg-navbar text-left fixed-top blur-effect"
      >
        <div className="d-block d-md-flex container-fluid ">
          <a href="http://edgeline.com.sg">
            <Img
              fixed={data.logoGray.childImageSharp.fixed}
              style={{
                width: 240,
                height: 36.74,
                display: "flex",
                alignItem: "center",
              }}
              className="img-fluid"
              imgStyle={{ objectFit: "contain", objectPosition: "center" }}
            />
          </a>

          <Scroll type="id" element="contact" offset={-60}>
            <a
              href="#contact"
              className="btn py-2 btn-link ml-md-auto d-none d-md-block signup-link"
            >
              SIGN UP FOR FREE VISUALISATION
            </a>
          </Scroll>
        </div>
      </div>

      <Header />

      <section className="py-6 bg-light">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-5 order-md-1 order-2">
              <Img
                fluid={data.img_contactus.childImageSharp.fluid}
                style={{ height: "100%", width: "auto" }}
              />
            </div>
            <div id="contact" className="col-md-7 order-md-2 order-1">
              <div className="p-5">
                {/* <span style={{fontSize: "0.75rem"}} className="section-title">
                  <u>CONTACT US</u>
                </span> */}
                <h2 className=" section-title">GET IN TOUCH</h2>
                <p className="section-desc my-4">
                  *From now till 31<sup>st</sup> March 2024, receive a FREE
                  3D render and a<br />
                  visualisation walkthrough of your future home. T&C apply.
                </p>
                <SignUpForm />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 py-6 text-center">
              <div
                className="py-md-5"
                style={{ maxWidth: "640px", margin: "0 auto" }}
              >
                <h2
                  className="section-title"
                  data-sal="flip-up"
                  data-sal-duration="1000"
                >
                  THE GOAL
                </h2>
                <p
                  className="section-desc my-5"
                  data-sal="slide-up"
                  data-sal-duration="1000"
                >
                  Getting a fresh start with a new home is exciting but is
                  certainly not without its challenges. That is why we are here
                  to help. The first step to Interior design starts with your
                  idea of a dream home. With your vision and our expertise, our
                  strong emphasis on co-creation will see your dreams become
                  reality.
                </p>
                <Scroll type="id" element="contact" offset={0}>
                  <div class="col-12 mt-5">
                    <div class="text-center">
                      <a href="#" class="btn btn-dark-outline mt-2">
                        SIGN UP FOR FREE VISUALISATION (WORTH $900)
                      </a>
                    </div>
                  </div>
                </Scroll>
              </div>
            </div>
            <div className="col-md-4 mb-5 mb-md-0 p-0">
              <Img
                fluid={data.imgVisual.childImageSharp.fluid}
                data-sal-duration="1000"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white py-6">
        <div className="container text-center">
          <div className="row">
            <div className="col p-0">
              <VideoPlayer
                src="https://cdn.innovten.com/edgeline/landingpagev1.mp4"
                title="Testing"
              />
            </div>
          </div>
        </div>
      </section>

      <SectionTextButton
        theme="white"
        title="THE ULTIMATE DESIGN SECRET"
        description="Envisioning your future home no longer has to be limited to blueprints. Visualise your design choices with our solutions created using mood boards and material samples, paired with modern 3D tools. With this technology, we are able to construct a version of your home and visualise all possible design solutions in a 720-degree virtual space."
        btnText="TRY IT NOW"
        anchor="contact"
      />

      <section className="bg-light py-md-5">
        <div className="container text-center">
          <div className="row py-md-5">
            <div className="col-md-12 p-0">
              <ReactCompareImage
                leftImage="https://cdn.innovten.com/edgeline/img-before-1.jpg"
                rightImage="https://cdn.innovten.com/edgeline/img-after-1.jpg"
              />
            </div>
          </div>
        </div>
      </section>

      <SectionTextButton
        theme="white"
        title="ITS ALL ABOUT YOU"
        description={
          <span>
            Take the guesswork out of your interior design project.
            <br /> Finding the best match to your lifestyle, budget and taste
            has never been easier.
          </span>
        }
        btnText="BOOK AN INTERIOR DESIGNER TODAY"
        anchor="contact"
      />

      <section className="py-6">
        <div className="text-dark" style={{ backgroundColor: "#f8f9fa" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-right">
                <Img
                  fluid={data.image_howitworks.childImageSharp.fluid}
                  style={{ height: "100%", width: "auto" }}
                />
              </div>
              <div className="col-md-6 py-5">
                <div className="text-center mb-5">
                  <h2
                    className="section-title"
                    data-sal="flip-up"
                    data-sal-duration="1000"
                  >
                    HOW IT WORKS
                  </h2>
                </div>
                <HowItWorks
                  step="01"
                  title="Talk to us"
                  text="Tell us about your vision for your home during a consultation with us, no strings attached. Allow us to bring out the full potential of your vision through our ideas and design options."
                />
                <HowItWorks
                  step="02"
                  title="Spice Up Your Design Concept"
                  text="If you like our ideas, sign with us to see your personality come to life. Based on your concept and our design optimisations, decide on a customised solution that suits your lifestyle and budget considerations."
                />
                <HowItWorks
                  step="03"
                  title="Renovate & Furnish"
                  text="Enjoy a smooth and pain-free renovation process which brings virtual concepts to reality through the use of effective project coordination and close adherence to scheduled timeframes."
                />
                <HowItWorks
                  step="04"
                  title="Move In & Enjoy"
                  text="Come home to quality craftsmanship at every corner of your customised space. In addition, enjoy a hassle-free service warranty from the day of handover."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light py-6">
        <div className="container text-center">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h2
                className="section-title"
                data-sal="flip-up"
                data-sal-duration="1000"
              >
                why EDGELINE PLANNERS
              </h2>
            </div>
          </div>
          <div className="row py-5">
            <div className="col-md-4 pt-4">
              <CommentIcon width="64" height="64" fill="#343a40" />
              <h3
                className="section-desc mt-4"
                data-sal="zoom-in"
                data-sal-duration="1000"
              >
                PROFESSIONAL ADVISE
              </h3>
              <p
                className="section-desc"
                data-sal="slide-up"
                data-sal-duration="1000"
              >
                With over 23 years of experience, we customise design solutions
                tailored to your requirements
              </p>
            </div>
            <div className="col-md-4 pt-4">
              <BillIcon width="64" height="64" fill="#343a40" />
              <h3
                className="section-desc mt-4"
                data-sal="zoom-in"
                data-sal-duration="1000"
              >
                NO HIDDEN COSTS
              </h3>
              <p
                className="section-desc"
                data-sal="slide-up"
                data-sal-duration="1000"
              >
                We offer a detailed breakdown of all costs in our quote,
                providing full transparency
              </p>
            </div>
            <div className="col-md-4 pt-4">
              <TeamIcon width="64" height="64" fill="#343a40" />
              <h3
                className="section-desc mt-4"
                data-sal="zoom-in"
                data-sal-duration="1000"
              >
                QUALITY CRAFTSMANSHIP
              </h3>
              <p
                className="section-desc"
                data-sal="slide-up"
                data-sal-duration="1000"
              >
                Our skilled team of passionate professionals promise refined
                work with upmost care and an attention to detail
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Scroll type="id" element="contact" offset={0}>
                <a
                  href="#contact"
                  className="btn btn-dark-outline my-4"
                  data-sal="zoom-in"
                  data-sal-duration="1000"
                >
                  CONTACT US TODAY
                </a>
              </Scroll>
            </div>
          </div>
        </div>
      </section>

      <SectionTextButton
        theme="white"
        title={
          <>
            DESIGN MEETS FUNCTIONALITY
            <br />
            BUILT TO LAST
          </>
        }
        description="With decades of trade records and thousands of satisfied customers, Edgeline Planners has managed to extend our services, growing through word of mouth and positive feedback. We strive to continue to deliver on our promises of quality workmanship and smooth renovation handovers."
      />

      <section className="bg-light py-6">
        <div className="container-fluid text-center">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h2 className="section-title">WHAT OUR CUSTOMERS SAY</h2>
            </div>
          </div>
          <div className="row p-md-5">
            <Testimonial
              image={data.testimonialWilly.childImageSharp.fluid}
              clientImage="img-willy.png"
              name="Willy Tan"
              text="Our experience with Lead Designer, Irene Ho have been good. She has done our previous home and also our current home. We have confidence in handing the turnkey project to her. Her ideas are fresh & contemporary, also pleasing to many of friends & vendors who had visited & viewed our homes (former & current) & their comments were “It’s like a Show-flat, like a hotel” or every area so well thought of by her planning, even in the most awkward areas she can come up with a good plan and idea. She is a detailed person & it translates into our home where detailing matters to us. She is also on time for all appointments and stay on TOP of all the work schedule. A very resourceful designer and also a committed person who delivers most of what she committed to. Highly recommended company to turn your dream home to reality and Irene Ho, a Lead Designer that is able to put your ideas to reality!"
            />
            <Testimonial
              image={data.testimonialGrace.childImageSharp.fluid}
              clientImage="img-grace.png"
              name="Grace Loi"
              text="Sevon is a star! She is one of our blessings when we got our first home in Singapore. She helped my husband create a design that’s not only practical but most of all beautiful. We have not had a single guest in our home who didn’t like the renovation of our place. She knows how to listen very well and execute your vision well. She gave input and work hand in hand with my husband who is really meticulous himself. She was very hands on. There was no day we visited the site that we didn’t see her there making sure that everything is in order. She delivered it on time and exceeding our expectation. Even for the warranty period, she was very efficient ensuring everything was working well and touched up whatever was needed. Really happy with her service. I would recommend Sevon to anyone who needs a hassle and stress free renovation. Thanks again for everything 🥰"
            />
            <Testimonial
              image={data.testimonialSaminathan.childImageSharp.fluid}
              clientImage="img-saminathan.png"
              name="Saminathan s/o Shanmuga"
              text="Overall we are satisfied with. Mr Kent Lim as our designer / site in-charge. He is well mannered and listens to our feedback and make necessary changes at the same time gives his professional opinion. His design is simple, elegant and practical. Your carpentry work is good since what is shown in 3D design proposal actually done up in the same at the project site. This is commendable."
            />
            <Testimonial
              image={data.testimonialDesmond.childImageSharp.fluid}
              clientImage="img-desmond.png"
              name="Desmond Ng"
              text="Renovated my duplex penthouse few
              months back. My ID Specialist Bosco from Edgeline Planners did an excellent job. He is highly professional, knowledgeable and has a good eye for material details and colours coordination. Relatives and friends who visited were amazed by the beautiful ‘transformation’ of my place. Anyone looking for a very competent ID Pro...Bosco is the man to call! 👍"
            />
          </div>
        </div>
      </section>

      <section className="bg-white py-6">
        <div className="container text-center">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-4 col-md-2 text-center">
              <Img
                fluid={data.accreditation1.childImageSharp.fluid}
                className="img-fluid"
              />
            </div>
            <div className="col-4 col-md-2 text-center">
              <Img
                fluid={data.accreditation3.childImageSharp.fluid}
                className="img-fluid"
              />
            </div>
            <div className="col-4 col-md-2 text-center">
              <Img
                fluid={data.accreditation4.childImageSharp.fluid}
                className="img-fluid"
              />
            </div>
            <div className="col-4 col-md-2 text-center">
              <Img
                fluid={data.accreditation5.childImageSharp.fluid}
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>

      <FAQSection />

      <section className="bg-light py-6">
        <div className="container text-center">
          <div className="row mb-5">
            <div className="col-md-8 offset-md-2">
              <h2
                className="section-title"
                data-sal="flip-up"
                data-sal-duration="1000"
              >
                CONTACT US
              </h2>
            </div>
          </div>
          <div className="row text-center">
            <div
              className="col-md-3 my-3"
              data-sal="zoom-in"
              data-sal-duration="1000"
            >
              <h3 className="section-desc">CONTACT NO</h3>
              <a href="tel:+6582668559" className="text-dark section-desc">
                +65 8266 8559
              </a>
            </div>
            <div
              className="col-md-3 my-3"
              data-sal="zoom-in"
              data-sal-duration="1000"
            >
              <h3 className="section-desc">EMAIL ADDRESS</h3>
              <a
                href="mailto:planners@edgeline.com.sg"
                className="text-dark section-desc"
              >
                planners@edgeline.com.sg
              </a>
            </div>
            <div
              className="col-md-3 my-3"
              data-sal="zoom-in"
              data-sal-duration="1000"
            >
              <h3 className="section-desc">OPERATING HOURS</h3>
              <span className="text-dark section-desc">
                11am - 8pm
                <br />
                (Monday - Sunday)
              </span>
            </div>
            <div
              className="col-md-3 my-3"
              data-sal="zoom-in"
              data-sal-duration="1000"
            >
              <h3 className="section-desc">OFFICE ADDRESS</h3>
              <span className="text-dark section-desc">
                2 Jurong East Street 21,
                <br />
                IMM Building,
                <br />
                03-32, Singapore 609601
              </span>
            </div>
          </div>
        </div>
      </section>
      <SocialLinks />

      {/* <AppointmentCode /> */}

      <footer className="py-6" style={{ backgroundColor: "#E9EAEC" }}>
        <div className="container">
          <div className="row">
            <div className="col d-flex" style={{ justifyContent: "center" }}>
              <Img
                fluid={data.logo.childImageSharp.fluid}
                className="img-fluid"
                style={{ width: "400px" }}
              />
            </div>
          </div>
          <p className="mt-5 text-center small text-muted">
            {new Date().getFullYear()} © All Right Reserved Edgeline Planners
            Pte Ltd.{" "}
            <a href="https://innovten.com.my/web-design" className="text-muted">
              Web Design by innovten
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}
