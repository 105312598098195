import React, { useState } from "react";
import Img from "gatsby-image";

export default ({ image, text, clientImage, name }) => {
  const [expand, setExpand] = useState(false);
  const isShorten = text.length > 400;
  const shortText = isShorten ? text.substring(0, 400) : text;
  return (
    <div
      className="col-md-6 p-3 text-justify"
      data-sal="slide-up"
      data-sal-duration="1000"
    >
      <div className="row">
        <div className="col-md-4 py-2">
          <Img fluid={image} style={{ maxHeight: "160" }} />
        </div>
        <div className="col-md-8 py-2">
          <p
            className="m-0 small"
            style={{
              color: "rgba(0,0,0,.75)",
            }}
          >
            {expand ? text : shortText}
            {isShorten && !expand && (
              <span>
                ...{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setExpand(true);
                  }}
                >
                  Read More
                </a>
              </span>
            )}
          </p>
          <p
            className="m-0 py-3 small"
            style={{
              color: "rgba(0,0,0,1)",
            }}
          >
            <img
              src={"/3dwalkthrough/images/testimonial/" + clientImage}
              style={{ borderRadius: 12 }}
              alt={name}
              width="24"
              height="24"
            />
            {" - " + name}
          </p>
        </div>
      </div>
    </div>
  );
};
