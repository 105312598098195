import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default ({ theme }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "images/img-header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <header id="header" className="site-header bg-hero">
      <div className="hero-content">
        <div className="jumbotron jumbotron-fluid m-0">
          <div className="container">
            <div className="row">
              <div className="col py-6 px-3 text-center section-title">
                <h1
                  id="headline"
                  data-sal="zoom-in"
                  data-sal-duration="1000"
                  data-sal-easing="ease"
                  className="h1 mb-4 font-weight-bold "
                  style={{ color: "rgba(255,255,255,1)" }}
                >
                  COMPLIMENTARY 3D WALKTHROUGH
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-images">
        <Img
          fluid={data.heroImage.childImageSharp.fluid}
          style={{ height: "100%" }}
        />
      </div>
    </header>
  );
};
