import React, { useState } from "react";

export default ({}) => {
  const [active, setActive] = useState("0");

  const FAQ = ({ id, question, answer }) => (
    <div className={"card" + (active == id ? " active" : "")}>
      <div className="card-header" id={"heading-" + id}>
        <h2 className="mb-0">
          <button
            className="btn btn-link text-center section-title faq-title text-dark p-0 w-100"
            type="button"
            data-toggle="collapse"
            data-target={"#faq-" + id}
            aria-expanded="true"
            aria-controls={"faq-" + id}
            onClick={() => setActive(id)}
          >
            {question}
          </button>
        </h2>
      </div>
      <div
        id={"faq-" + id}
        className={"collapse" + (active == id ? " show" : "")}
        aria-labelledby="headingOne"
        data-parent={"#faq-" + id}
      >
        <div className="card-body section-desc">{answer}</div>
      </div>
    </div>
  );

  return (
    <section className="py-6" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="container">
        <h2
          className="section-title mb-5 text-center"
          data-sal="flip-up"
          data-sal-duration="1000"
        >
          FREQUENTLY ASKED QUESTIONS
        </h2>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="accordion" id="faqs">
              <FAQ
                id="1"
                question="Do I have to pay for the Interior Design visualisation?"
                answer="No, we are offering our high-resolution 3D visualisation service at zero cost for you. These renderings will help you picture what the end product would look like design-wise, allowing us to ensure that the designs fully satisfy your expectations."
              />
              <FAQ
                id="2"
                question="What are the benefits of signing up for the Interior Design visualisation?"
                answer="Our team of experienced designers and 3D experts are trained to deliver realistically rendered work, eliminating uncertainty between what you want and what you will get. Concerns are immediately identifiable and are remedied with suitable alternatives, reducing the potential for errors before construction even starts."
              />
              <FAQ
                id="3"
                question="When should I make a booking for my Interior Design Visualisation?"
                answer="We recommend engaging our services 3 months before your property transaction is completed. Typically, this timeline will allow us to complete the design process and preparations so that renovations can start immediately after you receive your keys."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
