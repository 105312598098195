import React from "react";
import { graphql } from "gatsby";
import Site from "../components/site";

export default ({ data }) => {
  return <Site data={data} />;
};

export const query = graphql`
  query {
    logoGray: file(relativePath: { eq: "images/logo_gray_wide.png" }) {
      childImageSharp {
        fixed(width: 800, height: 120) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    logo: file(relativePath: { eq: "images/logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accreditation1: file(
      relativePath: { eq: "images/accreditation/img-1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accreditation3: file(
      relativePath: { eq: "images/accreditation/img-3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accreditation4: file(
      relativePath: { eq: "images/accreditation/img-4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    accreditation5: file(
      relativePath: { eq: "images/accreditation/img-5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialDesmond: file(
      relativePath: { eq: "images/testimonial/img-testimonial-desmond.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialGrace: file(
      relativePath: { eq: "images/testimonial/img-testimonial-grace.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialWilly: file(
      relativePath: { eq: "images/testimonial/img-testimonial-willy.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialSaminathan: file(
      relativePath: { eq: "images/testimonial/img-testimonial-saminathan.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image_howitworks: file(relativePath: { eq: "images/img-howitworks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    img_contactus: file(relativePath: { eq: "images/img-contactus.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgVisual: file(relativePath: { eq: "images/img-visual.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    placeholder: file(relativePath: { eq: "images/img-placeholder169.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
