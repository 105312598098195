import React from "react";
const Video = ({ src, title, ...props }) => (
  <>
    <video style={{ width: "100%" }} controls>
      <source src={src} type="video/mp4" />
    </video>
    {/* <iframe
      src={videoSrcURL}
      title={videoTitle}
      style={{ position: "relative", top: 0, bottom: 0, left: 0, right: 0 }}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    /> */}
  </>
);
export default Video;
