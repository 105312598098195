import React from "react";

export default ({ step, title, text }) => {
  return (
    <div className="media" data-sal="slide-up" data-sal-duration="1000">
      <div
        className="px-3 h3"
        style={{ fontFamily: "serif", color: "rgba(0,0,0,0.85)" }}
      >
        {step}
      </div>
      <div className="media-body">
        <p
          className="text-uppercase"
          style={{
            fontSize: "0.875rem",
            lineHeight: "1.7142857143",
            letterSpacing: ".2142857143em",
            fontWeight: "bold",
            fontStyle: "normal",
            textTransform: "uppercase",
            marginBottom: ".5em",
            color: "rgba(0,0,0,1)",
          }}
        >
          {title}
        </p>
        <p
          style={{
            fontSize: "0.8125rem",
            // fontWeight: "300",
            lineHeight: "1.7692307692",
            letterSpacing: ".0384615385em",
            color: "rgba(0,0,0,.85)",
          }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
