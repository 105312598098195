import React from "react";
import Scroll from "../components/scroll";

export default ({ col, theme, title, description, btnText, anchor }) => {
  return (
    <section className={"bg-" + theme + " py-6"}>
      <div className="container text-center">
        <div className="row py-5">
          <div className={col || "col-md-10 offset-md-1"}>
            <h2
              className=" section-title"
              data-sal="flip-up"
              data-sal-duration="1000"
            >
              {title}
            </h2>
            <p
              className="section-desc my-5"
              data-sal="slide-up"
              data-sal-duration="1000"
            >
              {description}
            </p>
            {btnText && (
              <Scroll type="id" element={anchor} offset={0}>
                <div class="col-12 mt-5">
                  <div class="text-center">
                    {/* <hr class="divider" /> */}
                    <a href="#" class="btn btn-dark-outline mt-2">
                      {btnText}
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        style={{ marginTop: -2 }}
                      >
                        <path
                          fill="currentColor"
                          d="M14.221 13.051H6V10.95h8.221l-3.249-3.462L12.372 6l4.94 5.257.688.736-.69.751L12.372 18l-1.4-1.487z"
                        ></path>
                      </svg> */}
                    </a>
                    {/* <hr class="divider" /> */}
                  </div>
                </div>
              </Scroll>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
