import React from "react";

import { ReactComponent as WhatsappIcon } from "../../static/svgs/icon-whatsapp.svg";
import { ReactComponent as EmailIcon } from "../../static/svgs/icon-email.svg";
import FacebookLogo from "../../static/images/icon-facebook.png";
import InstagramLogo from "../../static/images/icon-instagram.png";
import WhatsappLogo from "../../static/images/icon-whatsapp.png";



export default function SocialComponent() {
  return (
    <div
      style={{
        position: "fixed",
        right: "1rem",
        bottom: "1rem",
        zIndex: 99
        // borderRadius: "3em 0.5em 0.5em 3em",
      }}
    >
      <ul class="list-unstyled">
        <li>
          <a href="https://wa.me/6582668559">
            <img class="social-logo" src={WhatsappLogo} alt="link to whatsapp" />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/EdgelinePlanners/">
            <img class="social-logo" src={FacebookLogo} alt="link to facebook" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/edgeline_planners/?hl=en">
            <img style={{marginBottom: 0}} class="social-logo" src={InstagramLogo} alt="link to instagram" />
          </a>
        </li>
        {/* <li
          class="mb-1"
          style={{
            padding: "0.75rem",
            backgroundColor: "#343a40",
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <a target="_blank" href="mailto:enquiry@edgeline.com.sg">
            <EmailIcon width="32" height="32" fill="#fff" />
          </a>
        </li>
        <li
          class="mb-1"
          style={{
            padding: "0.75rem",
            backgroundColor: "#343a40",
            borderTopRightRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <a target="_blank" href="https://wa.me/+6582668559">
            <WhatsappIcon width="32" height="32" fill="#fff" />
          </a>
        </li> */}
      </ul>
    </div>
  );
}
